.App {
  text-align: center;
 
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.homeContainer {
  position: relative;
  z-index: 2;
}

.topMargin {
  margin-top: 2rem;
}

.lightBackground {
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5)
}